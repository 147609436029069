<template>
    <div class="noAuthorization"></div>
</template>

<script>
export default {
    // 暂无授权菜单
    name: 'no-authorization',
    components: {},
    props: {},
    data() {
        return {
        };
    },
    watch: {},
    computed: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.noAuthorization
    width 100%
    height 100%
    background url("./../../assets/images/default/noAuthorization.png") center no-repeat, #fff
    background-size 33%
</style>